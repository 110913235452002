<template>
  <div id="page-errors">
    <h5 class="m-4">{{ $t('ERROR.HEADER')}}</h5>

    <ErrorTable

    />

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import ErrorTable from '@/view/pages/ml/errors/ErrorTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'ErrorsPage',
  components: {
    ErrorTable,
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies'])
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  watch: {
    

  },
  methods: {
    
  }
};
</script>
